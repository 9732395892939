import React from "react"
import { DemoButton, GetStartedButton } from "./commonComponents"

export const fasterTooltip = (
  <div style={{ textAlign: "center" }}>
    Resolve tickets quickly with IrisAgent's <br /> suggested solutions.Give
    your customers the <br />
    ability to solve issues on their own.
  </div>
)
export const improveTooltip = (
  <div style={{ textAlign: "center" }}>
    Customers don't just want to feel valued. They
    <br /> want you to make their lives easier. Proactive <br />
    support generates loyalty.
  </div>
)
export const decreaseTooltip = (
  <div style={{ textAlign: "center" }}>
    Solve your customers' problems where they
    <br /> are. Free up your support and engineering <br />
    teams to focus on what really matters.
  </div>
)

export const fasterTooltip1 = (
  <div style={{ textAlign: "center" }}>
    Context is everything. Use signals <br /> and alerts from multiple sources{" "}
    <br /> to empower the right actions <br /> and optimize customer happiness.
  </div>
)
export const improveTooltip1 = (
  <div style={{ textAlign: "center" }}>
    Customers don’t just want to <br /> feel valued. They want you to <br />{" "}
    make their lives easier. Proactive <br /> support generates loyalty.
  </div>
)
export const decreaseTooltip1 = (
  <div style={{ textAlign: "center" }}>
    Know which customer accounts <br /> to prioritize based on business <br />{" "}
    objectives
  </div>
)

type IntegrationsMiddleSectionProps = {
  mainImage: any
  backgroundSectionColor: string
  descriptionTextTitle: JSX.Element
  descriptionTextContent: JSX.Element
  imageTextMiniature?: any
}

export const IntegrationsMiddleSectionImageLeft = ({
  mainImage,
  backgroundSectionColor,
  descriptionTextTitle,
  descriptionTextContent,
  imageTextMiniature,
}: IntegrationsMiddleSectionProps) => {
  return (
    <section
      id="about"
      className="about auto-detect-section auto-detect-section-less-top"
      style={{
        background: backgroundSectionColor,
      }}
    >
      <div className="row">
        <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
          <div
            className="auto-detect-img-not-mobile"
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "2%",
            }}
          >
            <img
              src={mainImage}
              style={{
                width: "88%",
                padding: "10%",
              }}
              alt="about section"
            />
          </div>
          <div
            className="auto-detect-info-container-50"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5% 10%",
            }}
          >
            <div className="auto-detect-subcontainer-mobile">
              <div className="mobile-view-new-design-display">
                <img
                  src={mainImage}
                  alt="about section"
                  style={{ width: "88%", paddingBottom: "10%" }}
                />
              </div>
                <div>
                  <img
                    src={imageTextMiniature}
                    style={{ marginBottom: "5%", width: "10%" }}
                    alt="about section"
                  />
                </div>
                <h2
                className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                style={{ marginBottom: "5%", marginTop: 0 }}
              >
                {descriptionTextTitle}
              </h2>
              <div className="integrations-middle-section-description-content web-view-new-design-display">
                {descriptionTextContent}
              </div>
              <div style={{ marginTop: "4%" }}>
                <GetStartedButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const IntegrationsMiddleSectionImageRight = ({
  mainImage,
  backgroundSectionColor,
  descriptionTextTitle,
  descriptionTextContent,
  imageTextMiniature,
}: IntegrationsMiddleSectionProps) => {
  return (
    <section
      id="about"
      className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile"
      style={{ background: backgroundSectionColor }}
    >
      <div className="row">
        <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
          <div
            className="auto-detect-info-container-40"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "2%",
            }}
          >
            <div>
              <div className="mobile-view-new-design-display">
                <img
                  src={mainImage}
                  style={{
                    width: "94%",
                    paddingBottom: "10%",
                  }}
                  alt="about section"
                />
              </div>
              <div
                className="section-title-new-design-integrations auto-detect-title"
                style={{ marginBottom: "5%" }}
              >

                  <div>
                    <img
                      src={imageTextMiniature}
                      style={{ marginBottom: "5%", width: "10%" }}
                      alt="about section"
                    />

                  <h2
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  {descriptionTextTitle}
                   </h2>
                </div>
                <div className="integrations-middle-section-description-content web-view-new-design-display">
                  {descriptionTextContent}
                </div>
              </div>
              <div style={{ marginTop: "2%" }}>
                <DemoButton />
              </div>
            </div>
          </div>
          <div
            className="auto-detect-img-not-mobile"
            style={{
              width: "50%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={mainImage}
              style={{
                width: "88%",
                padding: "10%",
              }}
              alt="about section"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
