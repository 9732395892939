import React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import {
  CTAButton,
  AttributeSection,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection
} from "../commonComponents"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import { capitalizeFirstLetter } from "../utils"

export default function Zoho() {
  const SERVICE_NAME = "zoho"
  const BACKGROUND_SRC = `/img/freshworks-bg.png`
  const BACKGROUND_WEB_POSITION = "100% 70px"
  const BACKGROUND_MOBILE_POSITION = "50% 50px"
  // It is done like this because the content is different in almost every page.
  return (
    <Layout
      keywords="Zoho integration, Zoho and Jira integration, Zoho chatbot, Zoho AI, Zoho Agent Assist"
      title="Elevate Zoho Operations with Agent Assist AI and GPT Chatbot"
      description="Discover how proactive AI can transform your Zoho operations. Boost efficiency, improve customer support & stay ahead of the curve with innovative AI technology"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/zoho/" />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            Up level your <br />
            <span className="title-fucsia-color">
              {capitalizeFirstLetter(SERVICE_NAME)} operations
            </span>
          </>
        }
        descriptionIntegration={
          <>
            <span>
              Resolve support tickets faster,
              <br/>improve agent performance, and deflect
              <br/>more than 40% of tickets with IrisGPT
              <br/>chatbot and Zoho integration
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
      />
      {/* <!-- End Hero --> */}

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={
          <CTAButton
            to="https://irisagent.com/get-a-demo/"
            label={`BOOK A DEMO`}
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Middle Section ======= --> */}

      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/freshworksMiddle1.webp"
        imageTextMiniature="/img/zohoMiniature.png"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Resolve common tickets automatically with AI</>}
        descriptionTextContent={
          <>
            Access appropriate resolutions based on support ticket data and
            knowledge base articles within Zoho. Resolve common support tickets using AI and
            IrisGPT to provide instant solutions to customers.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/zohoMiniature.png"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Deflect 40% of cases and chats with Zoho AI and IrisGPT Chatbot integration</>
        }
        descriptionTextContent={
          <>
            Trained on the world's largest large language models, IrisGPT generates multilingual, trustworthy, and highly accurate answers integrated in your Zoho chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/freshworksMiddle3.webp"
        imageTextMiniature="/img/zohoMiniature.png"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>Automate tagging, routing and prioritizing within Zoho</>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Get rid of manual and inaccurate ticket tagging. Generate ai
            sentiment analysis for Zoho tickets. Route tickets to the right team and prioritize.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}


      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does IrisAgent enhance customer service for Zoho clients?"
                  answer="IrisAgent enhances customer service by providing fast, accurate, and personalized responses across various communication channels. Its advanced capabilities enable it to comprehend human language nuances, ensuring a seamless and cohesive brand experience for Zoho clients and their customers."
                />
                <Faq
                  id="faq3"
                  question="How does IrisAgent AI resolve support tickets in Zoho faster?"
                  answer="IrisAgent AI and IrisGPT in Zoho saves agents' time and effort by automating responses to common, repetitive support queries. It also provides agents with real-time, context-aware suggestions, enabling them to resolve complex issues faster and more accurately. This results in improved customer satisfaction and reduced ticket resolution times."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI automate ticket tagging, routing, and sentiment analysis in Zoho?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry and applies them to new tickets without agent intervention. Our AI also analyzes Zoho tickets for language cues that reveal the sentiment. IrisAgent routes tickets to the right agent based on their domain and expertise. It uses built-in analytics to identify those product features with the most negative reactions so that support agents and product teams can better understand the source of the most 'how-to' tickets."
                />
                <Faq
                  id="faq5"
                  question="How can Zoho clients get started with IrisAgent?"
                  answer="It takes just a few minutes to get started with IrisAgent. Zoho clients can install the IrisAgent app from the Zoho marketplace and start using it right away. IrisAgent is designed to be user-friendly and intuitive, so you can start leveraging its advanced AI capabilities without any technical expertise."
                />
                <Faq
                  id="faq6"
                  question="What channels does IrisAgent support for communication?"
                  answer="IrisAgent supports multiple communication channels, including email, chat, and social media platforms. Its omnichannel approach enables Zoho clients to engage with their customers wherever they are, providing a unified experience across all channels."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Cta Section --> */}
      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
